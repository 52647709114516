const React = require('react');
const { shape, string, arrayOf, oneOf } = require('prop-types');
const classnames = require('classnames');
const HSFilledIcon = require('../hs-filled-icon');
const StyledLabel = require('../../../styled-label');

const namespace = 'ui-vpp-highlighted-specs__highlighted-attributes';

const HSHighlightedAttributes = ({ attributes, orientation }) => (
  <div className={classnames(namespace, { [`${namespace}--${orientation}`]: orientation })}>
    {attributes.map(({ picture, name, value }) => (
      <div
        className={classnames(`${namespace}__card`, { [`${namespace}__card--${orientation}`]: orientation })}
        key={name.text}
      >
        <span className={`${namespace}__card-border`} />
        <div className={`${namespace}__card-content`}>
          <HSFilledIcon
            className={`${namespace}__card-icon`}
            picture={{ bg_color: picture.background_color, bg_type: 'ROUNDED', url: picture.url }}
          />
          <div className={`${namespace}__card-labels`}>
            <StyledLabel className={`${namespace}__card-labels__name`} {...name} />
            <StyledLabel className={`${namespace}__card-labels__value`} {...value} />
          </div>
        </div>
      </div>
    ))}
  </div>
);

HSHighlightedAttributes.propTypes = {
  attributes: arrayOf(
    shape({
      name: shape({
        text: string,
        color: string,
      }),
      value: shape({
        text: string,
        color: string,
      }),
      picture: shape({
        url: {
          src: string,
        },
      }),
    }),
  ).isRequired,
  orientation: oneOf(['HORIZONTAL', 'VERTICAL']),
};

HSHighlightedAttributes.defaultProps = {
  orientation: 'HORIZONTAL',
};

module.exports = HSHighlightedAttributes;
