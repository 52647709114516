const { loadable } = require('nordic/lazy');
const React = require('react');
const { useMemo } = require('react');
const { arrayOf, shape, func, string } = require('prop-types');

// Basics
const ComponentList = require('../component-list');
const withMappedProps = require('../../utils/withMappedProps');
const classnames = require('classnames');

// Highlighted Specs Components
const StyledLabel = require('../styled-label');
const labelToProps = require('../styled-label/label-to-props');
const compatsToProps = require('../compats-widget/block/compats-to-props');
const HSHighlightedAttributes = require('./components/hs-highlighted-attributes');
const HSHighlightedAttributesList = require('./components/hs-highlighted-attributes-list');
const HSDiscreteBar = require('./components/hs-discrete-bar');
const HSContinousBar = require('./components/hs-continous-bar');
const HSKeyValue = require('./components/hs-key-value');
const HSKeyValueCarousel = require('./components/hs-key-value-carousel');
const HSActionRow = require('./components/hs-action-row');
const useTrackView = require('../../hooks/use-track-view');

const CompatsWidget = loadable(() => import('../compats-widget/block'));

const namespace = 'ui-vpp-highlighted-specs';

const availableComponents = {};
availableComponents.label_component = withMappedProps(StyledLabel)(labelToProps);
availableComponents.compats_widget_block = withMappedProps(CompatsWidget)(compatsToProps);
availableComponents.composed_highlight = withMappedProps(CompatsWidget)(compatsToProps);
availableComponents.highlighted_attributes = HSHighlightedAttributes;
availableComponents.highlighted_attributes_list = HSHighlightedAttributesList;
availableComponents.discrete_bar = HSDiscreteBar;
availableComponents.continuous_bar = HSContinousBar;
availableComponents.key_value = HSKeyValue;
availableComponents.key_value_carousel = HSKeyValueCarousel;
availableComponents.technical_specifications = HSActionRow;

const HIGHLIGHTED_NEW_EXPERIENCE = 'highlighted_specs_attrs_new';

const useInitialState = (components, getDispatch, feature_name) =>
  useMemo(
    () => ({
      components: components?.map((c, _, list) => {
        if (c.id === 'technical_specifications') {
          return {
            ...c,
            existAttributes:
              list.filter(
                ({ type }) => type === 'continuous_bar' || type === 'key_value' || type === 'key_value_carousel',
              )?.length > 0,
            getDispatch,
            feature_name,
          };
        }
        return { ...c, getDispatch, feature_name };
      }),
    }),
    [components, getDispatch, feature_name],
  );

const HighlightedSpecsAttrs = ({ components, viewport_track, getDispatch, feature_name }) => {
  const initialState = useInitialState(components, getDispatch, feature_name);
  const viewRef = useTrackView(viewport_track, { threshold: 0.45 });

  return (
    <section
      ref={viewRef}
      className={classnames(namespace, {
        'ui-vpp-highlighted-specs-rex': feature_name === HIGHLIGHTED_NEW_EXPERIENCE,
      })}
    >
      <ComponentList availableComponents={availableComponents} initialState={initialState} />
    </section>
  );
};

HighlightedSpecsAttrs.propTypes = {
  components: arrayOf(shape()).isRequired,
  viewport_track: shape({}),
  getDispatch: func,
  feature_name: string,
};

HighlightedSpecsAttrs.defaultProps = {
  viewport_track: null,
};

module.exports = React.memo(HighlightedSpecsAttrs);
