const actions = require('../../../actions/vip');

const compatsToProps = props => {
  const { getDispatch, feature_name, ...restProps } = props;
  const dispatch = getDispatch();

  return {
    components: [
      {
        ...restProps,
      },
    ],
    namespace: 'ui-vpp-highlighted-specs',
    modifier: 'dark',
    onSubmitForm: (selectedInput, updateState, deviceType) =>
      new Promise(resolve => {
        resolve(dispatch(actions.getCompatsProduct({ selectedInput, updateState, feature_name, deviceType })));
      }),
    onRemove: deviceType =>
      new Promise(resolve => {
        resolve(dispatch(actions.cleanFormCompats({ feature_name, deviceType })));
      }),
  };
};

module.exports = compatsToProps;
