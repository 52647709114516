const React = require('react');
const { string, number, oneOf } = require('prop-types');
const classnames = require('classnames');
const applyClasses = require('../../../lib/apply-classes');

const namespace = 'ui-vpp-progress-bar';

const ProgressBar = ({ className, percentage, size, color, bgColor }) => {
  if (!percentage) {
    return null;
  }

  const containerClasses = applyClasses({
    bg_color: bgColor,
    className: classnames(`${namespace}__container`, size, className),
  });

  const fillClasses = applyClasses({
    bg_color: color,
    className: `${namespace}__fill`,
  });

  const fillPercentage = {
    width: `${percentage}%`,
  };

  return (
    <div className={containerClasses}>
      <div className={fillClasses} style={fillPercentage} />
    </div>
  );
};

ProgressBar.propTypes = {
  className: string,
  size: oneOf(['small', 'large']),
  percentage: number.isRequired,
  color: string,
  bgColor: string,
};

ProgressBar.defaultProps = {
  size: 'small',
  color: 'BLUE',
  bgColor: 'LIGHT_GRAY',
  className: null,
};

module.exports = ProgressBar;
