const React = require('react');
const { shape, string } = require('prop-types');
const classnames = require('classnames');
const applyClasses = require('../../../../lib/apply-classes');
const ImageComponent = require('../../../image/image-component');

const namespace = 'ui-vpp-highlighted-specs__filled-icon';

const HSFilledIcon = ({ className, picture }) => (
  <div
    aria-hidden="true"
    className={applyClasses({
      bg_color: picture.bg_color,
      className: classnames(className, namespace, { [`${namespace}--${picture.bg_type}`]: picture.bg_type }),
    })}
  >
    {picture.url && picture.url.src ? <ImageComponent image_type="picture" picture={picture} /> : null}
  </div>
);

HSFilledIcon.propTypes = {
  className: string,
  picture: shape({
    bg_color: string,
    bg_type: string,
    url: shape({ src: string }),
  }).isRequired,
};

HSFilledIcon.defaultProps = {
  className: null,
};

module.exports = HSFilledIcon;
