const React = require('react');
const { shape, string } = require('prop-types');

const componentEnhance = require('../../../../lib/component-enhance');
const StyledLabel = require('../../../styled-label');
const HSFilledIcon = require('../hs-filled-icon');

const namespace = 'ui-vpp-highlighted-specs__key-value';

const HSKeyValue = ({ title, picture }) => (
  <div className={namespace}>
    <HSFilledIcon
      picture={{
        bg_color: 'GRAY_40',
        bg_type: 'ROUNDED',
        url: picture.url,
      }}
    />
    <div className={`${namespace}__labels`}>
      {title && (
        <StyledLabel
          text={componentEnhance.jsx(title.text, title.values)}
          className={`${namespace}__labels__key-value`}
        />
      )}
    </div>
  </div>
);

HSKeyValue.propTypes = {
  title: shape({
    color: string,
    text: string,
    values: shape({}),
  }),
  picture: shape({
    url: shape({}),
  }).isRequired,
};

HSKeyValue.defaultProps = {
  title: null,
};

module.exports = HSKeyValue;
