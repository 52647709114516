const React = require('react');
const { string, number, oneOf, node } = require('prop-types');
const classnames = require('classnames');

const namespace = 'ui-vpp-discrete-bar';

const DiscreteBar = ({ className, modifier, emptyBarLabel, fullBarLabel, divisions, highlighted }) => {
  if (!divisions) {
    return null;
  }

  const intervals = [];

  for (let i = 1; i <= divisions; i += 1) {
    intervals.push(i);
  }

  return (
    <div aria-hidden="true" className={classnames(namespace, className)}>
      <div className={classnames(`${namespace}__bar`, { [`${namespace}__bar--${modifier}`]: modifier })}>
        {intervals.map(n => (
          <span
            className={classnames(`${namespace}__bar__interval`, {
              [`${namespace}__bar__interval--highlighted`]: n === highlighted,
            })}
            key={n}
          />
        ))}
      </div>
      {emptyBarLabel && fullBarLabel ? (
        <div className={`${namespace}__description`}>
          <span className={`${namespace}__description__label`}>{emptyBarLabel}</span>
          <span className={`${namespace}__description__label`}>{fullBarLabel}</span>
        </div>
      ) : null}
    </div>
  );
};

DiscreteBar.propTypes = {
  className: string,
  emptyBarLabel: node,
  fullBarLabel: node,
  divisions: number.isRequired,
  highlighted: number,
  modifier: oneOf(['rounded', 'base']),
};

DiscreteBar.defaultProps = {
  className: null,
  emptyBarLabel: null,
  fullBarLabel: null,
  highlighted: -1,
  modifier: 'base',
};

module.exports = DiscreteBar;
