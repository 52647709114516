const React = require('react');
const { shape, bool, string, arrayOf } = require('prop-types');
const withSeeMore = require('../../../action-see-more');
const HSStripedSpecs = require('../hs-striped-specs');

const namespace = 'ui-vpp-highlighted-specs__see-more';
const HEIGHT_BOX = 400;

const SpecsWithSeeMore = withSeeMore(HSStripedSpecs);

const HSActionRow = ({ action, specs, title, existAttributes }) => (
  <SpecsWithSeeMore
    className={namespace}
    collapsedProps={{ specs }}
    expandedProps={{ specs }}
    action={action}
    modalTitle={title}
    modalClassName={`${namespace}__modal`}
    modalHash="highlighted-specs"
    hasPreview={!existAttributes}
    previewHeight={HEIGHT_BOX}
    useCanSeeEverything
  />
);

HSActionRow.propTypes = {
  action: shape({
    label: shape({
      text: string,
      color: string,
    }),
    target: string,
  }).isRequired,
  specs: arrayOf(shape({})).isRequired,
  title: string.isRequired,
  existAttributes: bool,
};

module.exports = React.memo(HSActionRow);
