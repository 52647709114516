const React = require('react');
const { shape, string, number } = require('prop-types');
const componentEnhance = require('../../../../lib/component-enhance');
const DiscreteBar = require('../../../data-display/discrete-bar');
const StyledLabel = require('../../../styled-label');
const HSFilledIcon = require('../hs-filled-icon');

const namespace = 'ui-vpp-highlighted-specs__discrete-bar';

const HSDiscreteBar = ({ title, bar_config, empty_bar_label, full_bar_label, picture }) => (
  <div className={namespace}>
    <HSFilledIcon
      picture={{
        bg_color: 'GRAY_40',
        bg_type: 'ROUNDED',
        url: picture.url,
      }}
    />
    <div className={`${namespace}__attribute`}>
      <StyledLabel
        text={componentEnhance.jsx(title.text, title.values)}
        className={`${namespace}__title`}
        data-testid="discrete-bar-title"
      />
      <DiscreteBar
        className={`${namespace}__discrete`}
        divisions={bar_config.full_range}
        highlighted={bar_config.value}
        emptyBarLabel={<StyledLabel {...empty_bar_label} data-testid="discrete-bar-empty-label" />}
        fullBarLabel={<StyledLabel {...full_bar_label} data-testid="discrete-bar-full-label" />}
      />
    </div>
  </div>
);

HSDiscreteBar.propTypes = {
  title: shape({
    text: string,
    color: string,
  }).isRequired,
  bar_config: shape({
    full_range: number,
    value: number,
  }).isRequired,
  empty_bar_label: shape({
    text: string,
    color: string,
  }).isRequired,
  full_bar_label: shape({
    text: string,
    color: string,
  }).isRequired,
  picture: shape({
    bg_color: string,
    url: shape({ src: string }),
  }).isRequired,
};

module.exports = HSDiscreteBar;
