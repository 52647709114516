const React = require('react');
const { shape, string, number } = require('prop-types');
const componentEnhance = require('../../../../lib/component-enhance');
const ProgressBar = require('../../../data-display/progress-bar');
const StyledLabel = require('../../../styled-label');
const HSFilledIcon = require('../hs-filled-icon');

const namespace = 'ui-vpp-highlighted-specs__continuous-bar';

const HSContinousBar = ({ title, progress_bar, picture }) => (
  <div className={namespace}>
    <HSFilledIcon
      picture={{
        bg_color: 'GRAY_40',
        bg_type: 'ROUNDED',
        url: picture.url,
      }}
    />
    <div className={`${namespace}__attribute`}>
      <div className={`${namespace}__labels`}>
        <StyledLabel text={componentEnhance.jsx(title.text, title.values)} data-testid="continous-bar-title" />
      </div>
      <ProgressBar
        className={`${namespace}__progress`}
        percentage={progress_bar.percentage}
        color={progress_bar.color}
        bgColor={progress_bar.background_color}
      />
    </div>
  </div>
);

HSContinousBar.propTypes = {
  title: shape({ text: string, color: string }).isRequired,
  progress_bar: shape({
    percentage: number.isRequired,
    color: string.isRequired,
    background_color: string.isRequired,
  }).isRequired,
  picture: shape({
    url: {
      src: string,
    },
  }).isRequired,
};

module.exports = HSContinousBar;
